import { DestroyRef, Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { auditTime, tap } from 'rxjs/operators';

import { BaseSystemModel, Guid, OLD_BaseSystemModel, Workflow } from '@models';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { IObjectEvent, StompService } from './stomp.service';
import { StoreService } from './store.service';

export const DynCacheItemTypeNames = ['workflow'] as const;
export type DynCacheItemType = {
    workflow: Workflow
}
export const newDynCacheItemInstance: { [T in typeof DynCacheItemTypeNames[number]]: (v: any) => DynCacheItemType[T] } = {
    workflow: v => new Workflow(v),
}
const TTL_CHECK_INTERVAL = 60 * 1000;

@Injectable()
export class DynCacheService {

    private _cache: {
        [itemType in typeof DynCacheItemTypeNames[number]]: {
            get?: (id: Guid) => Observable<DynCacheItemType[itemType] | undefined>,
            destroy?: (item: DynCacheItemType[itemType]) => void;
            items?: { [id: Guid]: any };
            expires?: { [id: Guid]: number };
            options?: {
                keepOnSyncLost?: true;
            }
        }
    } = {
        workflow: {
            get: id => this._api.getWorkflow(id),
        }
    };

    constructor(
        private _store: StoreService,
        private _auth: AuthService,
        private _api: ApiService,
        private _stomp: StompService,
        private _destroy: DestroyRef
    ) {
        const s_object = this._stomp.onObject.subscribe(e => this._handleObject(e));
        const s_objectEvent = this._stomp.onObjectEvent.subscribe(e => this._handleObjectEvent(e));
        const s_syncLost = this._store.syncLost.pipe(auditTime(5000)).subscribe(() => this.clearItems(true));
        const s_ttl = setInterval(() => this.checkExpiredItems(), TTL_CHECK_INTERVAL);
        this._destroy.onDestroy(() => {
            s_object.unsubscribe();
            s_objectEvent.unsubscribe();
            s_syncLost.unsubscribe();
            clearInterval(s_ttl);
            this.clearItems();
        });
    }

    getItem<T extends typeof DynCacheItemTypeNames[number]>(it: T, id: Guid): Observable<DynCacheItemType[T] | undefined> {
        const itc = this._cache[it];
        console.groupCollapsed(`[DynCache.get] ${it}#${id}`);
        if (itc) {
            if (itc.items?.[id] && (!itc.expires?.[id] || itc.expires[id] > Date.now())) {
                console.log('[DynCache.get] item exists in cache, return:', itc.items[id]);
                console.groupEnd();
                return of(itc.items[id]);
            }
            else if (itc.get) {
                console.log('[DynCache.get] item is absent, request from backend');
                console.groupEnd();
                return itc.get(id).pipe(
                    tap({
                        next: item => {
                            if (item?.id && item?.id == id) {
                                if (!itc.items) {
                                    itc.items = {};
                                }
                                itc.items[id] = item;
                            }
                        }
                    })
                );
            }
            else {
                console.log('[DynCache.get] item is absent, get function is absent, return undef');
                console.groupEnd();
                return of(undefined);
            }
        }
        else {
            console.log('[DynCache.get] unknown object type, throw error');
            console.groupEnd();
            return throwError(() => 'Неизвестный тип объекта.');
        }
    }

    deleteItem<T extends typeof DynCacheItemTypeNames[number]>(it: T, id: Guid): boolean {
        console.log(`[DynCache.delete] ${it}#${id}`);
        const itc = this._cache[it];
        if (itc && itc.items?.[id]) {
            delete itc.items[id];
            if (itc.expires?.[id]) {
                delete itc.expires[id];
            }
            return true;
        }
        return false;
    }

    clearItemsType<T extends typeof DynCacheItemTypeNames[number]>(it: T): void {
        const itc = this._cache[it];
        if (itc) {
            if (itc.items) {
                for (const k in itc.items) {
                    if (itc.items.hasOwnProperty(k)) {
                        delete itc.items[k];
                    }
                }
            }
            if (itc.expires) {
                for (const k in itc.expires) {
                    if (itc.expires.hasOwnProperty(k)) {
                        delete itc.expires[k];
                    }
                }
            }
        }
    }

    clearItems(syncLost = false): void {
        for (const it of DynCacheItemTypeNames) {
            if (this._cache[it] && (!syncLost || !this._cache[it].options?.keepOnSyncLost)) {
                this.clearItemsType(it);
            }
        }
    }

    checkExpiredItems(): void {
        for (const it of DynCacheItemTypeNames) {
            const itc = this._cache[it];
            if (itc && itc.items && itc.expires) {
                for (const k in itc.items) {
                    if (itc.items.hasOwnProperty(k) && itc.expires[k] && itc.expires[k] > Date.now()) {
                        delete itc.items[k];
                        delete itc.expires[k];
                    }
                }
            }
        }
    }

    private _handleObject(obj: BaseSystemModel | OLD_BaseSystemModel): void {
        if (!obj?._type || !obj?.id) {
            return;
        }
        const it = DynCacheItemTypeNames.indexOf(obj._type as any) != -1 ? obj._type as typeof DynCacheItemTypeNames[number] : undefined;
        if (it) {
            const itc = this._cache[it];
            if (itc && itc.items && itc.items[obj.id]) {
                itc.destroy?.(itc.items[obj.id]);
                itc.items[obj.id] = newDynCacheItemInstance[it](obj);
            }
        }
    }

    private _handleObjectEvent(e: IObjectEvent): void {
        if (!e || !e.type) {
            return;
        }
        const it = DynCacheItemTypeNames.indexOf(e.type as any) != -1 ? e.type as typeof DynCacheItemTypeNames[number] : undefined;
        if (it && e.ids) {
            e.ids.forEach(id => this.deleteItem(it, id));
        }
    }

}
